var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view content-padding contract-parties"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"contract-parties-controls"},[_c('Checkbox',{key:_vm.updateKey,attrs:{"label":_vm.labels.include_admin_users_in_to_selection,"name":"admins-included","value":_vm.adminsIncluded},on:{"changed":function($event){return _vm.kindToggle('admins', $event)}}})],1),_c('div',{class:{
          'contract-parties-controls': true,
          'contract-parties-dynamic': true,
          'contract-parties-disabled': !_vm.adminsIncluded,
        }},[_c('Checkbox',{key:_vm.updateKey,attrs:{"label":_vm.labels.include_all_admin_users_in_to_selection,"name":"all-admins","value":_vm.allAdminsSelected},on:{"changed":function($event){return _vm.switchAllParties('admins', $event)}}})],1),(_vm.parties.admins)?_c('div',{class:{
          'contract-parties-list': true,
          'contract-parties-dynamic': true,
          'contract-parties-disabled': !_vm.adminsIncluded || _vm.allAdminsDisabled,
        }},_vm._l((_vm.parties.admins),function(admin){return _c('div',{key:("admin_" + (admin.id)),staticClass:"contract-parties-list-partie"},[_c('span',[_vm._v(" "+_vm._s(admin.username)+" ")]),_c('Button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.allAdminsDisabled),expression:"!allAdminsDisabled"}],class:[{ 'disabled-btn': _vm.signed.includes(admin.id) }],attrs:{"label":_vm.partieAction(admin.selected),"variant":"btn-purple","styles":_vm.actionBtnStyle,"disabled":_vm.signed.includes(admin.id)},on:{"click":function($event){return _vm.togglePartie('admins', admin.id)}}})],1)}),0):_c('div',{staticClass:"text-center contract-parties-list"},[_vm._v(" "+_vm._s(_vm.labels.no_admins)+" ")])]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"contract-parties-controls"},[_c('Checkbox',{key:_vm.updateKey,attrs:{"label":_vm.labels.include_account_users_in_to_selection,"name":"accounts-included","value":_vm.accountsIncluded},on:{"changed":function($event){return _vm.kindToggle('accounts', $event)}}})],1),_c('div',{class:{
          'contract-parties-controls': true,
          'contract-parties-dynamic': true,
          'contract-parties-disabled': !_vm.accountsIncluded,
        }},[_c('Checkbox',{key:_vm.updateKey,attrs:{"label":_vm.labels.include_all_account_users_in_to_selection,"name":"all-accounts","value":_vm.allAccountsSelected},on:{"changed":function($event){return _vm.switchAllParties('accounts', $event)}}})],1),(_vm.parties.accounts)?_c('div',{class:{
          'contract-parties-list': true,
          'contract-parties-dynamic': true,
          'contract-parties-disabled': !_vm.accountsIncluded || _vm.allAccountsDisabled,
        }},_vm._l((_vm.parties.accounts),function(account){return _c('div',{key:("account_" + (account.id)),staticClass:"contract-parties-list-partie"},[_c('span',[_vm._v(" "+_vm._s(account.username)+" ")]),_c('Button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.allAccountsDisabled),expression:"!allAccountsDisabled"}],attrs:{"label":_vm.partieAction(account.selected),"variant":"btn-purple","styles":_vm.actionBtnStyle},on:{"click":function($event){return _vm.togglePartie('accounts', account.id)}}})],1)}),0):_c('div',{staticClass:"text-center contract-parties-list"},[_vm._v(" "+_vm._s(_vm.labels.no_users)+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }